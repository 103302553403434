import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import List from '../../components/Common/List'

import * as s from '../../pages/news.module.scss'

const Bim360VSSignalDocs = () => {
  return (
    <>
      <SEO
        title="Чем заменить Autodesk BIM360 | Среда общих данных для строительства и проектирования"
        description="Многие строительные компании задаются вопросом, чем заменить Autodesk BIM 360 и как выбрать лучший СОД, что он должен включать и какой функционал ключевой. Мы разработали среду общих данных с учетом пожеланий рынка и с упором на возможности Autodesk Construction Cloud, а также добавили специальные решения для российского рынка."
      />
      <NewsSectionBlock date="20.05.2024">
        <Typography variant="h1" color="blue" className="mb-0">
          Как и чем заменить Autodesk BIM 360?
        </Typography>
        <Typography variant="body1" className="mt-0">
          …и любую другую среду общих данных
        </Typography>
        <Typography variant="body1" mt={32}>
          Autodesk BIM 360 в России задал высокую планку стандарта для СОДов,
          все кто работал с ним четко знают и понимают, что должно быть в
          продукте, который они возьмут в работу внутри своей компании.
        </Typography>
        <Typography variant="body1">
          Сейчас активно развиваются порядка четырех сервисов, которые могут
          своим функционалом закрыть функционал BIM 360. Но вы скорее читаете
          эту статью не для того, чтобы узнать об этих продуктах, а для того,
          чтобы получить ответ на вопрос, который видите в заголовке.
        </Typography>
        <Typography variant="body1" className="mb-0">
          Итак, чем же заменить Autodesk BIM 360 в России?
        </Typography>
        <Typography variant="body1" className="mt-0">
          И ответом будет SIGNAL DOCS, а мы рассказываем почему?
        </Typography>
        <Typography variant="h2" color="blue">
          Что такое SIGNAL DOCS?
        </Typography>
        <Typography variant="body1">
          SIGNAL DOCS был разработан на замену Autodesk BIM 360 / ACC - это
          специализированная среда общих данных для ведения технического
          юридически значимого документооборота строительных проектов.
        </Typography>
        <Typography variant="body1">
          Мы взяли основной функционал BIM 360 DOCS и реализовали в своем SIGNAL
          DOCS
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../../assets/images/news/BIM360-vs-SIGNAL-DOCS/image-1.png"
            alt="SIGNAL DOCS"
            title="SIGNAL DOCS"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Замечания, согласования, просмотр документации в браузере,
          версионность и сравнение версий, создание маркапов и пометок, выгрузка
          аналитики - все теперь доступно в SIGNAL DOCS.
        </Typography>
        <Typography variant="body1">
          При этом, BIM 360 был не готов работать с требованиями и желаниями
          российского рынка и …
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Поэтому мы пошли дальше и сделали больше!
        </Typography>
        <Typography variant="h2" color="blue">
          Преимущества SIGNAL DOCS
        </Typography>
        <div className={cn(s.contentWrap)}>
          <div className={s.textWrap}>
            <Typography variant="h3" className="mt-0">
              Полноценное администрирование
            </Typography>
            <Typography variant="body1">
              В SIGNAL DOCS не надо писать в техподдержку, чтобы добавить или
              удалить сотрудников. Вы сами администрируете ваш хаб, создаете
              проекты, подключаете пользователей и управляете доступами.
            </Typography>
          </div>
          <StaticImage
            width={300}
            src="../../assets/images/news/BIM360-vs-SIGNAL-DOCS/image-2.png"
            alt="Полноценное администрирование"
            title="Полноценное администрирование"
            placeholder="blurred"
          />
        </div>
        <div className={cn(s.contentWrap, s.margin_top_48)}>
          <StaticImage
            width={300}
            src="../../assets/images/news/BIM360-vs-SIGNAL-DOCS/image-3.png"
            alt="Профессиональный строительный дашборд"
            title="Профессиональный строительный дашборд"
            placeholder="blurred"
          />
          <div className={s.textWrap}>
            <Typography variant="h3" className="mt-0">
              Профессиональный строительный дашборд
            </Typography>
            <Typography variant="body1">
              В SIGNAL DOCS реализованы специализированные строительные экраны
              аналитики, отработанные на реальных проектах крупнейших
              застройщиков. Дашборд даёт исчерпывающий ответ на все возникающие
              вопросы о ходе строительства.
            </Typography>
          </div>
        </div>
        <div className={cn(s.contentWrap, s.margin_top_48)}>
          <div className={s.textWrap}>
            <Typography variant="h3" className="mt-0">
              Целостность ИТ-архитектуры компании
            </Typography>
            <Typography variant="body1">
              SIGNAL DOCS дополняет существующие IT-продукты компании,
              интегрируется с такими инструментами как MS Project, Primavera,
              Spider Project, 1C, Excel, Google-таблицы и др.
            </Typography>
          </div>
          <StaticImage
            width={300}
            src="../../assets/images/news/BIM360-vs-SIGNAL-DOCS/image-4.png"
            alt="Целостность ИТ-архитектуры компании"
            title="Целостность ИТ-архитектуры компании"
            placeholder="blurred"
          />
        </div>
        <div className={cn(s.contentWrap, s.margin_top_48)}>
          <StaticImage
            width={300}
            src="../../assets/images/news/BIM360-vs-SIGNAL-DOCS/image-5.png"
            alt="Юридически значимая, квалифицированная ЭЦП"
            title="Юридически значимая, квалифицированная ЭЦП"
            placeholder="blurred"
          />
          <div className={s.textWrap}>
            <Typography variant="h3" className="mt-0">
              Юридически значимая, квалифицированная ЭЦП
            </Typography>
            <Typography variant="body1">
              SIGNAL DOCS позволяет подписывать любые файлы, загруженные в
              систему с помощью УКЭП согласно п. 5.16 СП 48.13330.2019, при этом
              подписание можно встроить в маршрут согласования, утвержденный в
              компании.
            </Typography>
          </div>
        </div>
        <div className={cn(s.contentWrap, s.margin_top_48)}>
          <div className={s.textWrap}>
            <Typography variant="h3" className="mt-0">
              Стабильный просмотр BIM-моделей
            </Typography>
            <Typography variant="body1">
              В SIGNAL DOCS встроен самый лучший в мире вьювер на базе Autodesk
              Forge, который позволяет просматривать 78 CAD-форматов (в том
              числе DWG, RVT, IFC, NWD). Также мы предусмотрели
              импортонезависимый Tangl-вьювер для просмотра IFC и RVT. Выбор
              какой использовать остается за вами.
            </Typography>
          </div>
          <StaticImage
            width={300}
            src="../../assets/images/news/BIM360-vs-SIGNAL-DOCS/image-6.png"
            alt="Стабильный просмотр BIM-моделей"
            title="Стабильный просмотр BIM-моделей"
            placeholder="blurred"
          />
        </div>
        <div className={cn(s.contentWrap, s.margin_top_48)}>
          <StaticImage
            width={300}
            src="../../assets/images/news/BIM360-vs-SIGNAL-DOCS/image-7.png"
            alt="Автоматическое сравнение PDF и BIM-моделей"
            title="Автоматическое сравнение PDF и BIM-моделей"
            placeholder="blurred"
          />
          <div className={s.textWrap}>
            <Typography variant="h3" className="mt-0">
              Автоматическое сравнение PDF и BIM-моделей
            </Typography>
            <Typography variant="body1">
              Пользователю больше не нужно всматриваться и «вживую» сравнивать
              на двух экранах, все изменения в чертежах показаны на одном файле,
              раскрашены цветом, а на моделях — изолированы и отсортированы по
              типу изменений.
            </Typography>
          </div>
        </div>
        <div className={cn(s.contentWrap, s.margin_top_48)}>
          <div className={s.textWrap}>
            <Typography variant="h3" className="mt-0">
              Полнофункциональная мобильная версия
            </Typography>
            <Typography variant="body1">
              Открывайте те же документы, проходите процессы согласования,
              выдавайте замечания, как на компьютере, но уже со смартфона в
              мобильном интерфейсе.
            </Typography>
          </div>
          <StaticImage
            width={300}
            src="../../assets/images/news/BIM360-vs-SIGNAL-DOCS/image-8.png"
            alt="Полнофункциональная мобильная версия"
            title="Полнофункциональная мобильная версия"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Специальные решения для российского рынка
        </Typography>
        <Typography variant="body1">
          SIGNAL DOCS не просто повторяет и дополняет возможности Autodesk BIM
          360, он адаптирован под специфику работы в России.
        </Typography>
        <List>
          <li>
            Для тех кто в работе пользуется 1С, есть возможность интеграции с 1С
          </li>
          <li>
            Государство на госзаказах обязывает внедрять и использовать ИСУП, мы
            добавили возможность интеграции с ИСУП
          </li>
          <li>
            Тип ЭЦП используемый в SIGNAL DOCS является юридически значимой
            усиленной квалифицированной электронной подписью (УКЭП), которую
            можно использовать при подписании любых документов
          </li>
          <li>
            Если переживаете, что Autodesk заблокирует использование Forge на
            территории РФ, то мы также дополнительно внедрили импортонезависимый
            инструмент для просмотра 3D-моделей моделей - Tangl Viewer.
          </li>
          <li>
            Сервера SIGNAL DOCS расположены на территории РФ в датацентрах
            Яндекс.Облако, но также хранение данных может быть осуществлено на
            ваших внутренних серверах
          </li>
        </List>
        <Typography variant="h2" color="blue">
          Заключение
        </Typography>
        <Typography variant="body1">
          В заключение можно сказать, что SIGNAL DOCS представляет собой
          современное и эффективное решение для управления проектами в области
          строительства и проектирования. Это облачный сервис, который
          предлагает широкий спектр функций для совместной работы над проектами,
          управления документацией и контроля за выполнением задач.
        </Typography>
        <Typography variant="body1">
          SIGNAL DOCS обладает рядом преимуществ перед Autodesk BIM 360, таким
          образом, переход на SIGNAL DOCS позволит повысить эффективность
          управления проектами, улучшить качество документации и обеспечить
          более высокий уровень безопасности данных. SIGNAL DOCS является
          современным и перспективным решением для компаний, работающих в
          области строительства и проектирования, и может стать основой для
          создания единой среды для управления проектами.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default Bim360VSSignalDocs
